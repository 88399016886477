export enum LogHistory {
    GET_LOG_HISTORY_STRAT = "GET_LOG_HISTORY_STRAT",
    GET_LOG_HISTORY_SUCCESS = "GET_LOG_HISTORY_SUCCESS",
    GET_LOG_HISTORY_FAIL = "GET_LOG_HISTORY_FAIL",

    LOGS_FILTER_START = 'LOGS_FILTER_START',
    LOGS_SEARCH_START = 'LOGS_SEARCH_START',
    RESET_FILTER_AND_SEARCH = 'RESET_FILTER_AND_SEARCH',

    GET_ATTACHEMENT_DATA_STRAT = "GET_ATTACHEMENT_DATA_STRAT",
    GET_ATTACHEMENT_DATA_SUCCESS = "GET_ATTACHEMENT_DATA_SUCCESS",
    GET_ATTACHEMENT_DATA_FAIL = "GET_ATTACHEMENT_DATA_FAIL",

    DELETE_ACTIVITY_START = "DELETE_ACTIVITY_START",
    DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS",
    DELETE_ACTIVITY_FAIL = "DELETE_ACTIVITY_FAIL",

    ACTIVITIES_FILTER_START = 'ACTIVITIES_FILTER_START',

    UPDATE_NOTE_START = "UPDATE_NOTE_START",
    UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS",
    UPDATE_NOTE_FAIL = "UPDATE_NOTE_FAIL",

    SET_USER_NAME_CASE_NUMBER = 'SET_USER_NAME_CASE_NUMBER',

    EDIT_NOTE_DRAWER = "EDIT_NOTE_DRAWER",

    DELETE_NOTE_START = "DELETE_NOTE_START",
    DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS",
    DELETE_NOTE_FAIL = "DELETE_NOTE_FAIL",

    RUN_ACTIVITY_DRAWER = "RUN_ACTIVITY_DRAWER",
    
    PRINT_CANCEL_START = "PRINT_CANCEL_START",
    PRINT_CANCEL_SUCCESS = "PRINT_CANCEL_SUCCESS",
    PRINT_CANCEL_FAIL = "PRINT_CANCEL_FAIL",

    NOTE_NOTIFIED_STATUS_START = "NOTE_NOTIFIED_STATUS_START",
    NOTE_NOTIFIED_STATUS_SUCCESS = "NOTE_NOTIFIED_STATUS_SUCCESS",
    NOTE_NOTIFIED_STATUS_FAIL = "NOTE_NOTIFIED_STATUS_FAIL",
}