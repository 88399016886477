import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "us.collection.partpayments/services";
import { AddPartPaymentAction } from "us.collection.partpayments/actions";
import { IReturnAction } from "us.collection/interfaces";
import {
  AddPartPayment,
  partPaymentType,
} from 'us.collection.partpayments/constants';
import { IExistingPartPayment } from 'us.collection.partpayments/reducers/AddPartPayment/Interfaces'
import { ICalculatePartPayment } from 'us.collection.partpayments/reducers/AddPartPayment/Interfaces'
import { $MessageBox } from "us.common/components";
import {CreatePartPaymentRequest} from "us.collection.partpayments/repository"



const AddPartPaymentSagas = {
  get: function* (action: IReturnAction<number>) {
    const params = action.payload.data as any;
    try {
      const data:IExistingPartPayment = yield call(API.AddPartPayment.PartPayment.get, params);
      yield put(AddPartPaymentAction.GetExistingPartPayment.success(data));
    } catch (error) {
      yield put(AddPartPaymentAction.GetExistingPartPayment.fail(error as any));
    }
  },
  save: function* (action: any) {
    const params:ICalculatePartPayment = action.payload.data?.requestBody as ICalculatePartPayment;
    try {
      let data;
      if (params.partPaymentType == partPaymentType.NO_OF_INSTALLMENT) {
        data = yield call(API.AddPartPayment.PartPayment.save, params);
      } else {
        const partPaymentFirstAPIResponse = yield call(
          API.AddPartPayment.PartPayment.save,
          params
        );
        if (partPaymentFirstAPIResponse.schemaList?.length > 0) {
          data = yield call(
            API.AddPartPayment.PartPayment.save,
            CreatePartPaymentRequest(params, partPaymentFirstAPIResponse)
          );
        } else {
          $MessageBox(
            'info',
            'US.COLLECTION.PARTPAYMENTS:DETAIL.CALCULATE_NUMBER_OF_INSTALLMENT_COUNT_FAILED',
            '',
            ''
          );
        }
      }
      if (data?.isSaved) {
        $MessageBox(
          'success',
          'US.COLLECTION.PARTPAYMENTS:DETAIL.PART_PAYMENT_PLAN_SAVED_SUCCESSFULLY',
          '',
          ''
        );
      }
      if (
        data?.isDCFAdded &&
        !action.payload.data?.calculateType &&
        !data?.isSaved
      ) {
        $MessageBox(
          'info',
          'US.COLLECTION.PARTPAYMENTS:DETAIL.DCF_ADDED_BY_THE_SYSTEM',
          '',
          ''
        );
      }
      yield put(
        AddPartPaymentAction.CalculatePartPayment.success({
          plan: data,
          calculateType: action.payload.data?.calculateType,
        })
      );
    } catch (error) {
      yield put(AddPartPaymentAction.CalculatePartPayment.fail(error as any));
    }
  },
  delete: function* (action: any) {
    const params=action.payload.data;
    try {
      const data  = yield call(API.AddPartPayment.PartPayment.delete, params);
      if(!data?.isError){
        $MessageBox(
          'success',
          'US.COLLECTION.PARTPAYMENTS:DETAIL.PART_PAYMENT_PLAN_REMOVED_SUCCESSFULLY',
          '',
          ''
        );
      }
      yield put(AddPartPaymentAction.DeletePartPayment.success(data));
    } catch (error) {
      yield put(AddPartPaymentAction.DeletePartPayment.fail(error as any));
    }
  },
  edit: function* (action: any) {
    const params = action.payload.data;
    try {
      const data = yield call(API.AddPartPayment.PartPayment.edit, params);
      if(!data?.isError){
        $MessageBox(
          'success',
          'US.COLLECTION.PARTPAYMENTS:DETAIL.UPDATED_SUCCESSFULLY',
          '',
          ''
        );
      }
      yield put(AddPartPaymentAction.EditPartPayment.success(data));
    } catch (error) {
      yield put(AddPartPaymentAction.EditPartPayment.fail(error as any));
    }
  },
};

const PartPaymentBMDSagas = {
  get: function* (action: any) {
    const params = action.payload.data as any;
    try {
      const data  = yield call(API.AddPartPayment.partPaymentBMD.get, params);
      yield put(AddPartPaymentAction.PartPaymentBMD.success(data));
    } catch (error) {
      yield put(AddPartPaymentAction.PartPaymentBMD.fail(error as any));
    }
  }
};

const PaymentAgreementSagas = {
  save: function* (action: any) {
    const params = action.payload.data;
    try {
      const data  = yield call(API.AddPartPayment.paymentAgreement.save, params);
      $MessageBox(
        'success',
        'US.COLLECTION.PARTPAYMENTS:DETAIL.PAYMENT_AGREEMENT_SAVED_SUCCESSFULLY',
        '',
        ''
      );
      yield put(AddPartPaymentAction.PaymentAgreement.success(data));
    } catch (error) {
      yield put(AddPartPaymentAction.PaymentAgreement.fail(error as any));
    }
  },
};

const PaymentsByInstallmentSagas = {
  get: function* (action: IReturnAction<number>) {
    const params = action.payload.data as any;
    try {
      const data:IExistingPartPayment = yield call(API.AddPartPayment.paymentsByInstallment.get, params);
      yield put(AddPartPaymentAction.PaymentsByInstallment.success({payments: data, installmentId: params.installmentid}));
    } catch (error) {
      yield put(AddPartPaymentAction.PaymentsByInstallment.fail(error as any));
    }
  }
};

export default [
  takeLatest(AddPartPayment.GET_EXISTING_PART_PAYMENT_START, AddPartPaymentSagas.get),
  takeLatest(AddPartPayment.CALCULATE_PART_PAYMENT_START, AddPartPaymentSagas.save),
  takeLatest(AddPartPayment.GET_PART_PAYMENT_BMDS_START, PartPaymentBMDSagas.get),
  takeLatest(AddPartPayment.ADD_PAYMENT_AGREEMENT_START, PaymentAgreementSagas.save),
  takeLatest(AddPartPayment.DELETE_PART_PAYMENT_START, AddPartPaymentSagas.delete),
  takeLatest(AddPartPayment.GET_PAYMENT_BY_INSTALLMENT_START, PaymentsByInstallmentSagas.get),
  takeLatest(AddPartPayment.EDIT_PART_PAYMENT_START, AddPartPaymentSagas.edit),
]
