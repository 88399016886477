import { LogHistory } from "us.collection.routines-and-activities/constants/History";
import { initialState } from "./State";
import _ from "lodash";
import {
  formatActivityHistory,
  formatWorkflowHistory,
  formatNoteHistory,
  valueFilter,
  formatDocumentHistory,
  removeDeletedNote,
  updateNoteNotifyStatus
} from "us.collection.routines-and-activities/functions";
import { base64ToXmlString } from "us.collection.routines-and-activities/functions";

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case LogHistory.GET_LOG_HISTORY_STRAT:
      return Object.assign({}, state, {
        note: {
          data: [],
          isLoading: true,
        },
        creditorPortalNote: {
          data: [],
          isLoading: true,
        },
        activity: {
          data: [],
          isLoading: true,
        },
        summaryData: {
          data: [],
          isLoading: true,
        },
      });
    case LogHistory.GET_LOG_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        note: {
          data: payload?.data?.noteHistory,
          isLoading: false,
        },
        creditorPortalNote: {
          data: payload?.data?.creditorPortalNotes,
          isLoading: false,
        },
        activity: {
          data: payload?.data?.activityHistory,
          isLoading: false,
        },
        workflowHistory: payload?.data?.workflowHistory,
        document: {
          data: payload?.data?.documentHistory,
          isLoading: false,
        },
        filterValue: "all",
        searchValue: "all",

        summaryData: {
          data: [
            ..._.orderBy(
              [
                ...formatActivityHistory(
                  payload?.data?.activityHistory?.filter(
                    (item: any) => item.recordType === "pending"
                  )
                ),
              ],
              ["scheduledTime"],
              ["asc"]
            ),
            ..._.orderBy(
              [
                ...formatActivityHistory(
                  payload?.data?.activityHistory?.filter(
                    (item: any) => item.recordType !== "pending"
                  )
                ),
                ...formatWorkflowHistory(payload?.data?.workflowHistory),
                ...formatDocumentHistory(payload?.data?.documentHistory),
                ...formatNoteHistory(payload?.data?.noteHistory),
                ...formatNoteHistory(payload?.data?.creditorPortalNotes),
              ],
              ["date"],
              ["desc"]
            ),
          ],
          isLoading: false,
        },
      });
    case LogHistory.GET_LOG_HISTORY_FAIL:
      return Object.assign({}, state, {
        note: {
          data: [],
          isLoading: false,
        },
        creditorPortalNote: {
          data: [],
          isLoading: false,
        },
        activity: {
          data: [],
          isLoading: false,
        },
        summaryData: {
          data: [],
          isLoading: false,
        },
      });
    case LogHistory.LOGS_FILTER_START:
      return Object.assign({}, state, {
        filterValue: payload?.data?.filterValue,
        summaryData: {
          data: valueFilter(state, {
            filterValue: payload?.data?.filterValue,
          }),
          isLoading: false,
        },
      });
    case LogHistory.LOGS_SEARCH_START:
      return Object.assign({}, state, {
        searchValue: payload?.data,
        summaryData: {
          data: valueFilter(state, {
            searchValue: payload?.data,
          }),
          isLoading: false,
        },
      });
    case LogHistory.RESET_FILTER_AND_SEARCH:
      return Object.assign({}, state, {
        searchValue: {},
        filterValue: "all",
      });
    case LogHistory.GET_ATTACHEMENT_DATA_STRAT:
      return Object.assign({}, state, {
        attachement: {
          data: [],
          isLoading: true,
          content: "",
        },
      });
    case LogHistory.GET_ATTACHEMENT_DATA_SUCCESS:
      return Object.assign({}, state, {
        attachement: {
          data: payload?.data,
          isLoading: false,
          content: payload?.data[0]
            ? base64ToXmlString(payload?.data[0].content)
            : "",
        },
      });
    case LogHistory.GET_ATTACHEMENT_DATA_FAIL:
      return Object.assign({}, state, {
        attachement: {
          data: [],
          isLoading: false,
          content: "",
        },
      });
    case LogHistory.DELETE_ACTIVITY_SUCCESS:
      return Object.assign({}, state, {
        summaryData: {
          data:
            Array.isArray(state.summaryData?.data) &&
            state.summaryData?.data?.filter(
              (item: any) => item.id !== payload?.data?.historyId
            ),
          isLoading: false,
        },
      });
    case LogHistory.EDIT_NOTE_DRAWER:
      return Object.assign({}, state, {
        noteDrawer: payload?.data,
      });
    case LogHistory.RUN_ACTIVITY_DRAWER:
      return Object.assign({}, state, {
        runActivityDrawer: payload?.data,
      });
    case LogHistory.DELETE_NOTE_START:
      return Object.assign({}, state, {
        isNoteDeleting: true,
      });
    case LogHistory.DELETE_NOTE_SUCCESS:
      const { noteId } = payload?.data ?? {};
      return Object.assign({}, state, {
        note: {
          data: removeDeletedNote(state.note?.data, noteId),
          isLoading: false,
        },
        creditorPortalNote: {
          data: removeDeletedNote(state.creditorPortalNote?.data, noteId),
          isLoading: false,
        },
        summaryData: {
          data: removeDeletedNote(state.summaryData?.data, noteId),
          isLoading: false,
        },
        isNoteDeleting: false,
      });
    case LogHistory.DELETE_NOTE_FAIL:
      return Object.assign({}, state, {
        isNoteDeleting: false,
      });
    case LogHistory.UPDATE_NOTE_SUCCESS:
      const { noteHistory, activityHistory, creditorPortalNotes } =
        payload?.data ?? {};
      return Object.assign({}, state, {
        note: {
          data: noteHistory,
          isLoading: false,
        },
        creditorPortalNote: {
          data: creditorPortalNotes,
          isLoading: false,
        },
        activity: {
          data: activityHistory,
          isLoading: false,
        },
        summaryData: {
          data: valueFilter(
            {
              note: {
                data: noteHistory,
              },
              creditorPortalNote: {
                data: creditorPortalNotes,
              },
              activity: {
                data: activityHistory,
              },
            },
            state
          ),
        },
      });
    case LogHistory.NOTE_NOTIFIED_STATUS_SUCCESS:
      const { noteId: viewedNoteId } = payload?.data ?? {};
      return Object.assign({}, state, {
        note: {
          ...state.note,
          data: updateNoteNotifyStatus(state.note.data, viewedNoteId),
        },
        creditorPortalNote: {
          ...state.creditorPortalNote,
          data: updateNoteNotifyStatus(
            state.creditorPortalNote.data,
            viewedNoteId
          ),
        },
        summaryData: {
          ...state.summaryData,
          data: updateNoteNotifyStatus(state.summaryData.data, viewedNoteId),
        },
      });
    default:
      return state;
  }
};
