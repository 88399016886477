import React, { useState, useContext, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	$DatePicker,
	$Typography,
	$Divider,
	$Skeleton,
	$AmountLabel,
	$DateLabel,
	$Tooltip,
	$Button,
} from 'us.common/components';
import { BoxIcons, IconTypes } from 'us.icons';
import { Formik } from 'formik';
import './Style.scss';
import { CaseType } from 'us.helper/types/enums';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { setCurrentCaseTypeAndTransactionTypeAction } from 'us.collection.case/actions';
import * as Actions from 'us.collection.case/actions';
import { INavigationData } from 'us.collection/interfaces';
import {
	EntityTypes,
	TransactionNavigate,
	WorkflowState,
} from 'us.collection.case/constants';
import {
	DisplayAmount,
	DisplayLabel,
} from 'us.collection.case/components/Detail/AccountSummery/Components';
import {
	getColorForWorkFlowState,
	getTransactionType,
	sumCaseAmount,
	calculatePaymentAgreementBalance,
} from 'us.collection.case/components/Detail/AccountSummery/Functions';
import { ConfigurationContext } from 'us.common/ConfigurationContext/ConfigurationProvider';
import { IAccountSummery } from './Interfaces';
import { URLType } from 'us.collection/constants';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { setEnforcementDisplayName } from 'us.collection.case/functions';
import { appInsights } from 'us.helper/AppInsights';
import { RootState } from 'us.helper/types';

const { Text, Paragraph } = $Typography;

const AccountSummary: React.FC<IAccountSummery & PropsFromRedux> = (props) => {
	const { t } = useTranslation();
	const context = useContext(ConfigurationContext);
	const [selectInterestDate, setSelectInterestDate] =
		useState<moment.Moment>(moment());
	const accountSummaryPermission =
		context.componentPermission['case']?.components[
			'accountSummary'
		];

	const {
		currentDateFormat,
		accountSummeryWidgetData,
		metaData,
		history,
		location,
		interestCalculating,
		currentLanguage,
		caseType,
		workflow,
		setSummaryInterest,
		setFilter,
		setCurrentCaseTypeAndTransactionTypeAction,
	} = props;

	const { caseId, entityType, caseNo } = metaData.data ?? {};
	const state = location.state as INavigationData;
	const {
		caseApportionmentSummery,
		caseExceededPayments,
		partPaymentBasic,
		case: accCase,
		paymentAgreementBasic,
	} = accountSummeryWidgetData.data ?? {};

	const { total, payDate } = paymentAgreementBasic ?? {};

	const {
		workflowDescription = '',
		nextDueDate: workflowNextExecutionDate = '',
	} = workflow.data ?? {};

	const {
		balance = 0,
		paidAmount = 0,
		mainAmount = 0,
		mainAmountBalance = 0,
		collectionFee = 0,
		collectionFeeBalance = 0,
		caseCosts = 0,
		caseCostBalance = 0,
		courtFee = 0,
		courtFeeBalance = 0,
		otherAmount = 0,
		otherBalance = 0,
		interestCost = 0,
		interestMainAmount = 0,
		interestMainAmountBalance = 0,
		interestCostBalance = 0,
		dueDate = '',
		isEnforcement,
		courtType = '',
	} = accCase ?? {};

	const {
		creditorSum = 0,
		debtorSum = 0,
		bureauSum = 0,
		notAllRemitted,
	} = caseApportionmentSummery ?? {};

	const {
		nextDueAmount = 0,
		nextInstallmentNumber,
		totalInstallments,
		nextDueDate,
	} = partPaymentBasic ?? {};

	const closeStates: Array<string> = [
		WorkflowState.CLOSE,
		WorkflowState.CLOSED,
		WorkflowState.SUV_CLOSE_CASE,
	];

	const isLineAvailable = useCallback(
		(lineName: string): boolean => {
			if (accountSummaryPermission) {
				return accountSummaryPermission?.components[
					lineName
				]?.isEnabled;
			} else {
				return false;
			}
		},
		[accountSummaryPermission]
	);

	/**
	 * Navigation to interest UIs
	 * @param type -{string} - interest type
	 */
	const navigateToInterest = (type: string) => {
		try {
			history.push({
				...location,
				pathname: `/case/${caseNo}/interest`,
			});
			setFilter && setFilter({ type });
		} catch (error) {
			appInsights.trackException(
				`Account Summary Interest navigation Exception - ${error}`
			);
		}
	};

	/**
	 * Navigation to transactions UIs
	 * @param types -{string} -transaction type
	 */
	const navigateTransactions = (types: string) => {
		try {
			const transactionType = getTransactionType(types);
			history.push({
				...location,
				pathname: `/case/${caseNo}/transactions`,
			});
			if (entityType === CaseType.C) {
				setCurrentCaseTypeAndTransactionTypeAction(
					CaseType.C,
					transactionType
				);
			} else {
				setCurrentCaseTypeAndTransactionTypeAction(
					CaseType.S,
					transactionType
				);
			}
		} catch (error) {
			appInsights.trackException(
				`Account Summary Transaction navigation Exception - ${error}`
			);
		}
	};

	// Navigate
	const navigate = (url: string): void => {
		window.scrollTo(0, 0);
		history.push({
			...location,
			pathname: `/case/${caseNo}/${url}`,
		});
	};

	/**
	 * Calculate interest
	 */
	const calculateInterest = () => {
		try {
			setSummaryInterest &&
				setSummaryInterest({
					caseId,
					interestDate: selectInterestDate,
					type: entityType,
				});
		} catch (error) {
			appInsights.trackException(
				`Account Summary Interest Calculation Exception - ${error}`
			);
		}
	};

	/**
	 * Get sub-case Metadata
	 * @param subCase
	 */
	const handleSubCaseNavigation = (subCase: any) => {
		try {
			window.scrollTo(0, 0);
			const { subCaseNumber, exCaseNo } = subCase;
			history.push({
				...location,
				pathname: `/case/${exCaseNo}`,
				state: {
					...state,
					currentTab: URLType.SUBCASE,
					subCaseId: subCaseNumber,
				},
			});
		} catch (error) {
			appInsights.trackException(
				`Account Summary Get SubCase MetaData Exception - ${error}`
			);
		}
	};

	return (
		<Formik initialValues={{}} onSubmit={() => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
			}: any) => (
				<div
					className={`widget account-summary ${
						accountSummeryWidgetData.isLoading &&
						'p-3'
					}`}>
					<$Skeleton
						loading={
							accountSummeryWidgetData.isLoading
						}
						active
						paragraph={{ rows: 8 }}>
						<div
							className={`widget-header d-flex align-items-center px-3 py-2 tag-status-${getColorForWorkFlowState(
								workflowDescription
							)}`}>
							<span className='mr-auto'>
								<strong>
									{
										workflowDescription
									}
								</strong>
							</span>
							<span>
								{workflowNextExecutionDate && (
									<$DateLabel
										value={
											workflowNextExecutionDate
										}
									/>
								)}
							</span>
						</div>

						{((entityType === CaseType.C &&
							workflowDescription ===
								WorkflowState.LEGAL) ||
							(caseType ===
								CaseType.C &&
								isEnforcement) ||
							(caseType ===
								CaseType.C &&
								accountSummeryWidgetData
									.data
									?.case
									.isSentence)) && (
							<div className='px-3 pt-3 pb-2'>
								<div className='d-flex flex-wrap my-n1 mx-n2'>
									{entityType ===
										CaseType.C &&
										courtType && (
											<div className='dom-handler  mx-2 my-1'>
												<div className='handler-pic conciliation-handler'>
													<img alt='' />
												</div>
												<div className='handler-details'>
													<span className='handler-name'>
														{t(
															'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.COURT_TYPE'
														)}
													</span>
													<span className='handler-type'>
														{
															courtType
														}
													</span>
												</div>
											</div>
										)}
									{caseType ===
										CaseType.C &&
										isEnforcement && (
											<$Tooltip
												placement='top'
												title={setEnforcementDisplayName(
													currentLanguage,
													accCase
												)}>
												<div
													className='dom-handler dom-handler-clickable mx-2 my-1'
													onClick={() =>
														navigate(
															'enforcements'
														)
													}>
													<div className='handler-pic enforcement-handler'>
														<img alt='' />
													</div>
													<div className='handler-details'>
														<span className='handler-name'>
															{t(
																'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.ENFORCEMENT'
															)}
														</span>
														<span
															className='handler-type'
															style={{
																maxWidth: '120px',
															}}>
															<Paragraph
																className='m-0'
																ellipsis>
																{setEnforcementDisplayName(
																	currentLanguage,
																	accCase
																)}
															</Paragraph>
														</span>
													</div>
												</div>
											</$Tooltip>
										)}
									{caseType ===
										CaseType.C &&
										accountSummeryWidgetData
											.data
											?.case
											.isSentence && (
											<a
												className='dom-handler  mx-2 my-1'
												onClick={(
													e
												) => {
													e.preventDefault();
													navigate(
														'sentences'
													);
												}}>
												<div className='handler-pic sentence-handler'>
													<img alt='' />
												</div>
												<div className='handler-details flex-row align-items-center'>
													<span className='handler-name'>
														{t(
															'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.SENTENCE'
														)}
													</span>
												</div>
											</a>
										)}
								</div>
								<$Divider className='mt-3 mb-0' />
							</div>
						)}

						<div className='widget-body pb-4'>
							<div className='d-flex align-items-center mb-2 as-header'>
								<span className='mr-auto'>
									<strong>
										{t(
											'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.ACCOUNT_SUMMERY'
										)}
									</strong>
								</span>
								<div className='d-flex align-items-center'>
									<span className='mr-2'>
										{t(
											'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.INTEREST_UNTIL'
										)}
									</span>
									<$DatePicker
										name='interestUntil'
										defaultValue={moment(
											new Date(),
											currentDateFormat
										)}
										disabledDate={(
											day: any
										) =>
											!day ||
											(moment(
												dueDate
											).isValid() &&
												day.isBefore(
													moment(
														dueDate
													).startOf(
														'day'
													)
												)) ||
											closeStates.includes(
												workflowDescription
											)
										}
										onChange={(
											date: any
										) =>
											setSelectInterestDate(
												date
											)
										}
										value={
											selectInterestDate
										}
										size='small'
										format={
											currentDateFormat
										}
										style={{
											width: 110,
										}}
										disabled={
											(metaData.data &&
												isSubCaseTransferredToCase(
													metaData.data
												)) ||
											closeStates.includes(
												workflowDescription
											)
										}
									/>
									<$Tooltip
										placement='top'
										title={t(
											'US.COLLECTION.TRANSACTIONS:ACCOUNTSUMMERY.CALCULATE_INTEREST'
										)}>
										<$Button
											className='calculate-interest'
											type='link'
											size='small'
											onClick={() =>
												calculateInterest()
											}
											disabled={
												(metaData.data &&
													isSubCaseTransferredToCase(
														metaData.data
													)) ||
												closeStates.includes(
													workflowDescription
												)
											}>
											<BoxIcons
												type={
													IconTypes.BOX_ICON
												}
												name='calculate'
											/>
										</$Button>
									</$Tooltip>
								</div>
							</div>
							<div className='dom-amt-list-table'>
								<div className='list-table-row list-table-header'>
									<div className='list-table-col'>
										{' '}
									</div>
									<div className='list-table-col pr-3 text-right'>
										<DisplayLabel
											name={t(
												'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.AMOUNT'
											)}
										/>
									</div>
									<div className='list-table-col pr-3 text-right'>
										<DisplayLabel
											name={t(
												'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.PAID'
											)}
										/>
									</div>
									<div className='list-table-col text-right'>
										<DisplayLabel
											name={t(
												'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.BALANCE'
											)}
										/>
									</div>
								</div>
								{interestCalculating && (
									<div className='list-table-row list-table-header calculating'>
										<div
											className='list-table-col pr-3'
											style={{
												width: 150,
											}}>
											<$Skeleton
												loading={
													interestCalculating
												}
												active
												title={
													false
												}
												paragraph={{
													rows: 8,
												}}
											/>
										</div>
										<div className='list-table-col pr-3'>
											<$Skeleton
												loading={
													interestCalculating
												}
												active
												title={
													false
												}
												paragraph={{
													rows: 8,
												}}
											/>
										</div>
										<div className='list-table-col pr-3'>
											<$Skeleton
												loading={
													interestCalculating
												}
												active
												title={
													false
												}
												paragraph={{
													rows: 8,
												}}
											/>
										</div>
										<div className='list-table-col'>
											<$Skeleton
												loading={
													interestCalculating
												}
												active
												title={
													false
												}
												paragraph={{
													rows: 8,
												}}
											/>
										</div>
									</div>
								)}
								{!interestCalculating && (
									<>
										<DisplayAmount
											amount={
												mainAmount
											}
											balance={
												mainAmountBalance
											}
											clickNavigate={() =>
												navigateTransactions(
													TransactionNavigate.MAIN_AMOUNT
												)
											}
											name={t(
												'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.MAIN_AMOUNT'
											)}
										/>
										<DisplayAmount
											amount={
												collectionFee
											}
											balance={
												collectionFeeBalance
											}
											clickNavigate={() =>
												navigateTransactions(
													TransactionNavigate.COLLECTION_FEE
												)
											}
											name={t(
												'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.COLLECTION_FEE'
											)}
										/>
										<DisplayAmount
											amount={
												courtFee
											}
											balance={
												courtFeeBalance
											}
											clickNavigate={() =>
												navigateTransactions(
													TransactionNavigate.COURT_FEE
												)
											}
											name={t(
												'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.COURT_FEE'
											)}
										/>
										<DisplayAmount
											amount={
												caseCosts
											}
											balance={
												caseCostBalance
											}
											clickNavigate={() =>
												navigateTransactions(
													TransactionNavigate.CASE_COST
												)
											}
											name={t(
												'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.CASE_COST'
											)}
										/>
										<DisplayAmount
											amount={
												otherAmount
											}
											balance={
												otherBalance
											}
											clickNavigate={() =>
												navigateTransactions(
													TransactionNavigate.OTHER_COST
												)
											}
											name={t(
												'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.OTHER_COST'
											)}
										/>
										<DisplayAmount
											amount={
												interestMainAmount
											}
											balance={
												interestMainAmountBalance
											}
											clickNavigate={() =>
												navigateToInterest(
													TransactionNavigate.INTEREST_MAIN
												)
											}
											name={t(
												'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.INTEREST_MAIN'
											)}
										/>
										<DisplayAmount
											amount={
												interestCost
											}
											balance={
												interestCostBalance
											}
											clickNavigate={() =>
												navigateToInterest(
													TransactionNavigate.INTEREST_COST
												)
											}
											name={t(
												'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.INTEREST_COSTS'
											)}
										/>
										<div className='list-table-row'>
											<div className='list-table-col'>
												<a>
													<p
														className='mb-2'
														onClick={() =>
															navigateTransactions(
																TransactionNavigate.TOTAL
															)
														}>
														<strong>
															{t(
																'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.TOTAL'
															)}
														</strong>
													</p>
												</a>
											</div>
											<div className='list-table-col pr-3 text-right'>
												<strong>
													<$AmountLabel
														value={sumCaseAmount(
															accCase
														)}
													/>
												</strong>
											</div>
											<div className='list-table-col pr-3 text-right'>
												<strong>
													<$AmountLabel
														value={Math.abs(
															paidAmount
														)}
													/>
												</strong>
											</div>
											<div className='list-table-col text-right'>
												<strong>
													<$AmountLabel
														value={
															balance
														}
													/>
												</strong>
											</div>
										</div>
									</>
								)}

								{(creditorSum >
									0 ||
									bureauSum >
										0 ||
									debtorSum >
										0) && (
									<React.Fragment>
										<div className='list-table-row'>
											<div className='list-table-col'>
												<$Divider className='my-2' />
											</div>
											<div className='list-table-col'>
												<$Divider className='my-2' />
											</div>
											<div className='list-table-col'>
												<$Divider className='my-2' />
											</div>
											<div className='list-table-col'>
												<$Divider className='my-2' />
											</div>
										</div>

										<div className='list-table-row list-table-header'>
											<div className='list-table-col'>
												{' '}
											</div>
											<div className='list-table-col pr-3 text-right'>
												<DisplayLabel
													name={t(
														'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.CREDITOR'
													)}
												/>
											</div>
											<div className='list-table-col pr-3 text-right'>
												<DisplayLabel
													name={t(
														'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.BUREAU'
													)}
												/>
											</div>
											<div className='list-table-col text-right'>
												<DisplayLabel
													name={t(
														'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.DEBTOR'
													)}
												/>
											</div>
										</div>
										<div className='list-table-row'>
											<div className='list-table-col'>
												{t(
													'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.DISTRIBUTION'
												)}
												{notAllRemitted && (
													<sup>
														<Text type='success'>
															<strong>
																{t(
																	'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.NEW'
																)}
															</strong>
														</Text>
													</sup>
												)}
											</div>
											<div className='list-table-col pr-3 text-right'>
												<$AmountLabel
													value={
														creditorSum
													}
												/>
											</div>
											<div className='list-table-col pr-3 text-right'>
												<$AmountLabel
													value={
														bureauSum
													}
												/>
											</div>
											<div className='list-table-col text-right'>
												<$AmountLabel
													value={
														debtorSum
													}
												/>
											</div>
										</div>
									</React.Fragment>
								)}

								{caseExceededPayments &&
									caseExceededPayments.length >
										0 && (
										<>
											<div className='list-table-row'>
												<div className='list-table-col'>
													<$Divider className='my-2' />
												</div>
												<div className='list-table-col'>
													<$Divider className='my-2' />
												</div>
												<div className='list-table-col'>
													<$Divider className='my-2' />
												</div>
												<div className='list-table-col'>
													<$Divider className='my-2' />
												</div>
											</div>
											<div>
												<strong>
													{t(
														'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.TOO_MUCH_PAID'
													)}
												</strong>
											</div>
											<div className='list-table-row'>
												<div className='list-table-col pr-3 align-middle'>
													<DisplayLabel
														name={t(
															'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.PAYMENT_ID'
														)}
													/>
												</div>
												<div className='list-table-col pr-3 text-right align-middle'>
													<DisplayLabel
														name={t(
															'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.AMOUNT'
														)}
													/>
												</div>
												<div className='list-table-col pr-3 text-right align-middle'></div>
												<div className='list-table-col text-right align-middle'></div>
											</div>
											{caseExceededPayments.map(
												({
													paymentId,
													amount,
												}: {
													paymentId: number;
													amount: number;
												}) => {
													return (
														<div
															className='list-table-row'
															key={
																paymentId
															}>
															<div className='list-table-col pr-3 align-middle'>
																<Link
																	to={{
																		pathname: `/payment/${paymentId}/distribution`,
																		state: {
																			originate: 'payment',
																			accessTabs: [
																				'payment',
																			],
																			refreshCount: 0,
																			currentTab: 'payment',
																		},
																	}}>
																	{
																		paymentId
																	}
																</Link>
															</div>
															<div className='list-table-col pr-3 text-right align-middle'>
																<$AmountLabel
																	value={
																		amount
																	}
																/>
															</div>
															<div className='list-table-col pr-3 text-right align-middle'></div>
															<div className='list-table-col text-right align-middle'></div>
														</div>
													);
												}
											)}
										</>
									)}

								{total &&
									isLineAvailable(
										'paymentAgreement'
									) && (
										<>
											<div className='list-table-row'>
												<div className='list-table-col'>
													<$Divider className='mt-2 mb-1' />
												</div>
												<div className='list-table-col'>
													<$Divider className='mt-2 mb-1' />
												</div>
												<div className='list-table-col'>
													<$Divider className='mt-2 mb-1' />
												</div>
												<div className='list-table-col'>
													<$Divider className='mt-2 mb-1' />
												</div>
											</div>

											<div className='list-table-row'>
												<div className='list-table-col align-middle'>
													<a
														onClick={() =>
															navigate(
																'agreements'
															)
														}>
														{t(
															'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.AGREED_FINAL_SETTLEMENT'
														)}
													</a>
												</div>
												<div className='list-table-col pr-3 text-right align-middle'>
													<DisplayLabel
														name={t(
															'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.AMOUNT'
														)}
													/>
													<$AmountLabel
														value={calculatePaymentAgreementBalance(
															total,
															paidAmount
														)}
													/>
												</div>
												<div className='list-table-col pr-3 text-right align-middle'></div>
												<div className='list-table-col text-right align-middle'>
													<DisplayLabel
														name={t(
															'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.DUE_DATE'
														)}
													/>
													<$DateLabel
														value={
															payDate
														}
													/>
												</div>
											</div>
										</>
									)}

								{caseType ===
									CaseType.C &&
									(totalInstallments ??
										0) >
										0 &&
									isLineAvailable(
										'partPayment'
									) && (
										<React.Fragment>
											<div className='list-table-row'>
												<div className='list-table-col'>
													<$Divider className='my-1' />
												</div>
												<div className='list-table-col'>
													<$Divider className='my-1' />
												</div>
												<div className='list-table-col'>
													<$Divider className='my-1' />
												</div>
												<div className='list-table-col'>
													<$Divider className='my-1' />
												</div>
											</div>

											<div className='list-table-row'>
												<div className='list-table-col align-middle'>
													<a
														onClick={() =>
															navigate(
																'part-payments'
															)
														}>
														{t(
															'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.PART_PAYMENT'
														)}

														(
														{
															nextInstallmentNumber
														}

														/
														{
															totalInstallments
														}

														)
													</a>
												</div>
												<div className='list-table-col pr-3 text-right align-middle'>
													<DisplayLabel
														name={t(
															'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.AMOUNT'
														)}
													/>
													<$AmountLabel
														value={
															nextDueAmount
														}
													/>
												</div>
												<div className='list-table-col pr-3 text-right align-middle'></div>
												<div className='list-table-col text-right align-middle'>
													<DisplayLabel
														name={t(
															'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.NEXT_DUEDATE'
														)}
													/>
													<$DateLabel
														value={
															nextDueDate
														}
													/>
												</div>
											</div>
										</React.Fragment>
									)}
							</div>

							{accountSummeryWidgetData
								.data
								?.subCase &&
								accountSummeryWidgetData
									.data
									?.case
									?.caseType ==
									EntityTypes.CASE && (
									<div>
										<$Divider className='mt-1 mb-3' />
										<div className='mb-1'>
											<strong>
												{t(
													'US.COLLECTION.TRANSACTIONS:ACCOUNTSUMMERY.INVOICES'
												)}
											</strong>
										</div>
										<div className='d-flex flex-wrap'>
											{accountSummeryWidgetData.data?.subCase.map(
												(
													subCase: any
												) => (
													<a
														key={`${subCase.subCaseNumber}`}
														className='mr-4'
														onClick={() =>
															handleSubCaseNavigation(
																subCase
															)
														}
														data-testid='accountSummery-subcase-navigation'>{`${subCase.exCaseNo} (${subCase.invoiceRef})`}</a>
												)
											)}
										</div>
									</div>
								)}
						</div>
					</$Skeleton>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, dashboard, domainView } = state;
	const { currentDateFormat, currentLanguage } = common;
	const { accountSummeryWidgetData, interestCalculating } = dashboard;
	const { metaData } = domainView;
	return {
		currentDateFormat,
		accountSummeryWidgetData,
		caseNo: state.case.caseNo,
		metaData,
		interestCalculating,
		currentLanguage,
		workflow: domainView.workflow,
	};
};

const { interestAction } = Actions;
const { interest, summaryInterest } = interestAction;

const mapDispatchToProps = {
	setCurrentCaseTypeAndTransactionTypeAction,
	setFilter: interest.setFilter,
	setSummaryInterest: summaryInterest.update,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AccountSummary);
