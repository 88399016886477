import { put, call, select, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection.routines-and-activities/actions";
import * as API from "us.collection.routines-and-activities/services";
import { LogHistory } from "us.collection.routines-and-activities/constants/History";
import { formatLogHistory } from "us.collection.routines-and-activities/functions";
import { $Message, $MessageBox } from "us.common/components";
import _ from "lodash";
import { appInsights } from "us.helper";
import { RootState } from 'us.helper/types';
const { historyAction } = Actions;
const { note } = historyAction;

const logSagas = {
  history: {
    get: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.history.logs.get, params);
        const enableCreditorPortalNotes = yield select(
          (state: RootState) => state.AuthorizationProfile?.authorizationProfile?.enableCreditorPortalNotes
        );
        yield put(historyAction.logHistory.success(formatLogHistory(data, enableCreditorPortalNotes)));
      } catch (error) {
        yield put(historyAction.logHistory.fail(error as any));
        appInsights.trackException(`Get All history Saga Exception - ${error}`);
      }
    },
  },

  attachement: {
    get: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.history.attachment.get, params);
        if (
          (data.hasOwnProperty("code") && data?.code === 500) ||
          data?.code === 404
        ) {
          yield put(historyAction.attachment.fail({}));
        } else {
          yield put(historyAction.attachment.success(data));
        }
      } catch (error) {
        yield put(historyAction.attachment.fail(error as any));
        appInsights.trackException(`Get attachement Saga Exception - ${error}`);
      }
    },
  },
  activity: {
    delete: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.history.activity.delete, params);
        if (data.hasOwnProperty("isError") && !data?.isError) {
          $Message.success(data.message);
          yield put(historyAction.delete.success(params));
        }
      } catch (error) {
        yield put(historyAction.delete.fail(error as any));
        appInsights.trackException(`Delete activity Saga Exception - ${error}`);
      }
    },
  },
  note: {
    update: function* (action: any): any {
      const params = action.payload.data.params;
      const closeDrawer = action.payload.data.closeAction;
      try {
        const data = yield call(
          API.history.note.update,
          _.omit(params, "activityParams")
        );
        const enableCreditorPortalNotes = yield select(
          (state: RootState) => state.AuthorizationProfile?.authorizationProfile?.enableCreditorPortalNotes
        );
        if (data.hasOwnProperty("noteId")) {
          if (note?.openDrawer) {
            yield put(
              note?.openDrawer({
                title: "",
                visible: false,
              })
            );
          }
          $MessageBox(
            "success",
            "US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.NOTE_UPDATED_SUCCESSFULLY",
            "",
            ""
          );
          
          closeDrawer();
          const result = yield call(
            API.history.logs.get,
            params?.activityParams
          );
          yield put(note.success(formatLogHistory(result, enableCreditorPortalNotes)));
        } else {
          $Message.error(data?.message);
          yield put(historyAction.logHistory.fail({}));
        }
      } catch (error) {
        appInsights.trackException(`Upadate Note Saga Exception - ${error}`);
        yield put(historyAction.logHistory.fail({}));
      }
    },
    delete: function* (action: any): any {
      const params = action.payload.data.params;
      const closeDrawer = action.payload.data.closeAction;
      try {
        const data = yield call(API.history.note.delete, params);
        if (data.hasOwnProperty("isError") && !data?.isError) {
          if(data?.message)
          $MessageBox(
            "success",
            "US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.NOTE_DELETED_SUCCESSFULLY",
            "",
            ""
          );
          if (note?.openDrawer) {
            yield put(
              note?.openDrawer({
                title: "",
                visible: false,
              })
            );
          }
          yield put(historyAction.deleteNote.success(params));
          closeDrawer();
        } else {
          if(data?.errorNo == 2){
            $MessageBox(
              "error",
              "US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.ONLY_CREATED_USER_CAN_DELETE_THE_NOTE",
              "",
              ""
            );
          }else if(data?.errorNo == 3){
            $MessageBox(
              "error",
              "US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.NOTE_MODIFICATION_PERIOD_IS_EXCEEDED",
              "",
              ""
            );
          }else{
            $MessageBox(
              "error",
              "US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.NOTE_DELETE_FAILED",
              "",
              ""
            );
          }
          
          yield put(historyAction.deleteNote.fail({}));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.NOTE_DELETE_FAILED",
          "",
          ""
        );
        yield put(historyAction.deleteNote.fail({}));
        appInsights.trackException(`Delete Note Saga Exception - ${error}`);
      }
    },
    setNotifiedStatus: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.history.note.status, params);
        if (data?.code == 200) {
          yield put(historyAction.noteStatus.success({noteId: params?.noteId}));
        } else {
          yield put(historyAction.noteStatus.fail(new Error()));
        }
      } catch (error) {
        yield put(historyAction.noteStatus.fail(error as any));
        appInsights.trackException(`Note notify Saga Exception - ${error}`);
      }
    },
  },
  print: {
    cancel: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.history.activity.printCancel, params);
        if (data.hasOwnProperty("isError") && !data?.isError) {
          $Message.success(data?.message);
          yield put(historyAction.print.success(params));
          if (historyAction.logHistory.get) {
            yield put(historyAction.logHistory.get(params));
          }
          
        } else {
          $Message.error(data?.message);
          yield put(historyAction.print.fail(params));
        }
      } catch (error) {
        yield put(historyAction.delete.fail(error as any));
        appInsights.trackException(`Cancel Print Document Saga Exception - ${error}`);
      }
    },
  }
};
export default [
  takeLatest(LogHistory.GET_LOG_HISTORY_STRAT, logSagas.history.get),
  takeLatest(LogHistory.GET_ATTACHEMENT_DATA_STRAT, logSagas.attachement.get),
  takeLatest(LogHistory.DELETE_ACTIVITY_START, logSagas.activity.delete),
  takeLatest(LogHistory.UPDATE_NOTE_START, logSagas.note.update),
  takeLatest(LogHistory.DELETE_NOTE_START, logSagas.note.delete),
  takeLatest(LogHistory.PRINT_CANCEL_START, logSagas.print.cancel),
  takeLatest(LogHistory.NOTE_NOTIFIED_STATUS_START, logSagas.note.setNotifiedStatus),
];
